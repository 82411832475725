import styled, { css } from '@xstyled/styled-components'
import {
  siteWrapperCss,
  siteWrapperNoPaddingCss,
} from '@/modules/utils/siteWrapper'
import containerWidth from '@/modules/utils/containerWidth'
import { breakpoints } from '@xstyled/system'

export const ContainerAlt = styled.div`
  color: secondary;
  width: 100vw;
  position: relative;
  z-index: 2;
  max-height: 100vh;
  overflow-y: auto;
`

export const Container = styled.div`
  color: secondary;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  max-width: ${containerWidth()}px;
  padding-left: 32px;
  padding-right: 32px;

  ${siteWrapperCss}
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: black;
  }
`

export const SimpleContainer = styled.div`
  color: secondary;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  ${breakpoints({
    xl: css`
      padding-left: 32px;
      padding-right: 32px;
    `,
  })};

  ${siteWrapperCss}
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: black;
  }
`

export const ContainerNoPadding = styled.div`
  color: secondary;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  max-width: ${containerWidth()}px;

  ${siteWrapperNoPaddingCss}
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: black;
  }
`

export const NavigationLeft = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

interface Props {
  readonly flex: number
}

export const Left = styled.div<Props>`
  flex: ${(props) => props.flex};
  margin: xl;
`

export const Right = styled.div`
  flex: 2;
  margin: xl;
`

export const NavigationRight = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`
