import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PubSub from 'pubsub-js'
import { APP_PAGE_MOBILE_SLIDE_UPDATE, APP_VIEW_UPDATE } from '@/events'
import styled from '@xstyled/styled-components'

const WithSlideShowCheck: React.FC<{
  mobileContent: React.ReactNode[]
  fullWidth?: boolean
  autoPlay?: boolean
}> = ({ children, mobileContent, fullWidth, autoPlay }) => {
  const [view, setView] = useState(null)
  const [slide, setSlide] = useState(1)
  const [timeoutHandle, setTimeoutHandle] = useState(null)
  let viewUpdateSubscriptionHandler = null
  let slideUpdateSubscriptionHandler = null
  const showPageTimeout = 8000

  const showNextSlide = () => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle)
      setTimeoutHandle(null)
    }

    if (mobileContent.length < 2 || slide !== 1) {
      return
    }
    PubSub.publish(APP_PAGE_MOBILE_SLIDE_UPDATE, 1)
    setSlide(2)
  }

  const onSlideShowStart = () => {
    if (view === 'mobile') {
      if (autoPlay) {
        setTimeoutHandle(setTimeout(showNextSlide, showPageTimeout))
      }
      PubSub.publish(APP_PAGE_MOBILE_SLIDE_UPDATE, 0)
    }
  }

  const onViewUpdate = (action, newView: string) => {
    setView(newView)
  }

  const onSlideUpdate = (action, newSlide: number) => {
    setSlide(newSlide + 1)
  }

  useEffect(() => {
    viewUpdateSubscriptionHandler = PubSub.subscribe(
      APP_VIEW_UPDATE,
      onViewUpdate,
    )
    slideUpdateSubscriptionHandler = PubSub.subscribe(
      APP_PAGE_MOBILE_SLIDE_UPDATE,
      onSlideUpdate,
    )
    onSlideShowStart()
    if (view === 'desktop') {
      setSlide(1)
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }

      PubSub.unsubscribe(viewUpdateSubscriptionHandler)
      PubSub.unsubscribe(slideUpdateSubscriptionHandler)
    }
  }, [view])

  /* eslint-disable indent */
  return (
    <>
      {view === 'mobile' && !!mobileContent
        ? mobileContent.map(
            (contentEntry, index) =>
              slide === index + 1 && (
                <DynamicWidthContainer
                  fullWidth={fullWidth}
                  key={contentEntry.toString()}
                >
                  {contentEntry}
                </DynamicWidthContainer>
              ),
          )
        : children}
    </>
  )
}

const DynamicWidthContainer = styled.div`
  width: ${(props) => props.fullWidth && '100%'};
`

WithSlideShowCheck.defaultProps = {
  fullWidth: false,
  autoPlay: true,
}

WithSlideShowCheck.propTypes = {
  mobileContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  fullWidth: PropTypes.bool,
  autoPlay: PropTypes.bool,
}

export default WithSlideShowCheck
