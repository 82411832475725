import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import PartnerList from './our-partners/PartnerList'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import DropdownWithOverlay from '@/components/DropdownWithOverlay/DropdownWithOverlay'
import { APP_PARTNER_SELECT } from '@/events'
import PubSub from 'pubsub-js'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Left } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const partners = [
  'Hosting',
  'Ordering/Trading',
  'CDD/KYC/AML',
  'Fund pricing',
  'Portfolio transfers',
  'Payments (Interbanking)',
  'Payments (Corporate)',
  'Financial Planning',
  'Front-end development',
  'Customer Communication Channels',
  'Remuneration',
  'Payments',
  'Business Process management',
  'Technology',
  'Data Communication Standards',
]

const OurPartnersPage: React.FC = () => {
  const inProp = useContentInProp()

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/our-platform/partners"
            text="back"
          />
        )}
      </Left>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              fullWidth
              mobileContent={[
                <DropdownWithOverlay
                  update={(val) => PubSub.publish(APP_PARTNER_SELECT, val)}
                  title="choose a partner"
                  data={partners}
                />,
              ]}
            >
              <Box col={0.6} />
              <Box col={0.4}>
                <Box>
                  <PartnerList partners={partners} />
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
    </>
  )
}

export default OurPartnersPage
