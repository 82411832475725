import React, { useState, useRef, useEffect } from 'react'
import gsap from 'gsap'

import {
  SelectorWrap,
  DividerLine,
  ActiveEntryText,
  BackgroundWrap,
  DropDownEntry,
  BackgroundWrapInner,
} from './styled'
import ChevronIcon from '@/assets/images/svg/ChevronIcon'

const DropdownWithOverlay: React.FC<{
  data: string[]
  title: string
  update: (val: string) => void
}> = ({ data, title, update }) => {
  const [activeEntry, setActiveEntry] = useState(data[0])
  const [open, setOpen] = useState(false)
  const backgroundRef = useRef(null)
  const timeLineRef = useRef(null)

  const onOpenHandler = (newOpenState) => {
    setOpen(newOpenState)
  }

  useEffect(() => {
    if (activeEntry) {
      update(activeEntry)
    }
  }, [activeEntry])

  useEffect(() => {
    const createTLIntro = () => {
      const tl = gsap.timeline({
        repeat: 0,
        timeScale: 1,
        paused: true,
      })
      tl.fromTo(
        backgroundRef.current,
        { display: 'block', opacity: 0 },
        { opacity: 1 },
      )
      return tl
    }
    const createTLOutro = () => {
      const tl = gsap.timeline({
        repeat: 0,
        timeScale: 1,
        paused: true,
      })
      tl.fromTo(backgroundRef.current, { opacity: 1 }, { opacity: 0 })
      tl.set(backgroundRef.current, { display: 'none' })
      return tl
    }
    // eslint-disable-next-line
    timeLineRef.current?.kill()

    let tl = null

    if (open) {
      tl = createTLIntro()
    } else {
      tl = createTLOutro()
    }

    timeLineRef.current = tl

    tl.play()
  }, [open])

  return (
    <SelectorWrap>
      <ActiveEntryText
        onClick={() => {
          onOpenHandler(!open)
        }}
      >
        {activeEntry}
        <ChevronIcon open={open} />
      </ActiveEntryText>

      <DividerLine />
      <BackgroundWrap ref={backgroundRef}>
        <p>{title}</p>
        <BackgroundWrapInner>
          {data?.map((entry) => (
            <DropDownEntry
              onClick={() => {
                onOpenHandler(false)
                setActiveEntry(entry)
              }}
            >
              <p>{entry}</p>
            </DropDownEntry>
          ))}
        </BackgroundWrapInner>
      </BackgroundWrap>
    </SelectorWrap>
  )
}

export default DropdownWithOverlay
