import styled, { css } from '@xstyled/styled-components'
import { Paragraph } from '@/components/Paragraph'

export const Title = styled(Paragraph).attrs({
  variant: 'body',
})`
  opacity: 0;
  margin-bottom: 44px;
  text-transform: uppercase;

  font-size: xs;
  font-weight: bold;
`

export const ListContainer = styled.div`
  /* overflow: hidden; */
  height: calc(100vh - 335px);
  div {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: black;
    }
  }
`

export const List = styled.ul`
  /* /* overflow-y: auto; */
  /* overflow: hidden;
  height: calc(100vh - 335px); */
  list-style: none;

  li {
    padding-bottom: 24px;
  }
`

export const Bar = styled.div`
  transition: all 140ms cubic-bezier(0.61, 1, 0.88, 1);
  width: 0;
  margin-right: 8px;
  border-bottom: solid 1px #337f65;
`

export const PartnerLink = styled.span`
  transition: all 140ms cubic-bezier(0.61, 1, 0.88, 1);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #929292;
  background: none;
  border: none;
`
export const ChevronWrap = styled.div``

export const Li = styled.li<{ selected: boolean }>`
  opacity: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover ${Bar} {
    width: 20px;
  }

  &:hover ${PartnerLink} {
    color: #ffffff;
  }

  ${(props) =>
    props.selected
      ? css`
          ${Bar} {
            width: 20px;
          }

          ${PartnerLink} {
            color: #ffffff;
          }
        `
      : ''}
`
