import styled, { css, StyledComponent } from '@xstyled/styled-components'
import { variant, breakpoints } from '@xstyled/system'
import { Link } from '@reach/router'

const position = variant({
  prop: 'position',
  variants: {
    left: css`
      flex-direction: row;
    `,
    right: css`
      flex-direction: row-reverse;
    `,
  },
})

const sticky = variant({
  prop: 'position',
  variants: {
    left: css`
      position: absolute;
      left: 0;
    `,
    right: css`
      position: absolute;
      right: 0;
    `,
  },
})

const align = variant({
  prop: 'position',
  variants: {
    left: css`
      text-align: left;
    `,
    right: css`
      text-align: right;
    `,
  },
})

export const Container: StyledComponent<
  'div',
  Record<string, unknown>,
  { position: string },
  never
> = styled.div`
  display: flex;
  position: relative;
  ${position}
  ${breakpoints({
    /* eslint-disable sort-keys */
    xl: css`
      ${sticky}
    `,
  })}
`

export const BarContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 25px;
`

export const Bar: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.div`
  height: 1px;
  background-color: secondary;

  &:link,
  &:visited,
  &:active {
    color: secondary;
  }
  opacity: 0;
  width: 0;
`

interface VariantProps {
  variant: string
}

export const NavigationLink = styled(Link)<VariantProps>`
  font-size: xs;
  font-weight: bold;
  margin: sm;
  &:link,
  &:visited,
  &:active {
    color: secondary;
  }
  text-decoration: none;
  text-transform: uppercase;
  ${align}
`

export const ExternalLink = styled.a<VariantProps>`
  font-size: xs;
  font-weight: bold;
  margin: sm;
  &:link,
  &:visited,
  &:active {
    color: secondary;
  }
  text-decoration: none;
  text-transform: uppercase;
  ${align}
`

export const NavigationCopy = styled.span`
  opacity: 0;
`
