import React, { useRef, useEffect } from 'react'
import { ContainerVertical } from './styled'
import gsap from 'gsap'
import { Draggable } from 'gsap/Draggable'

type refType = {
  current: HTMLDivElement
}

interface Props {
  parentRef: refType
}

export const FlexVerticalDrag: React.FC<Props> = ({ parentRef, children }) => {
  const sliderRef = useRef(null)
  const sliderHeight = sliderRef.current?.clientHeight
  const parentHeight = parentRef?.current?.clientHeight
  let minimumHeight
  useEffect(() => {
    gsap.registerPlugin(Draggable)
    minimumHeight = -sliderHeight + parentHeight
    console.log(minimumHeight, sliderHeight, parentHeight)
    if (sliderHeight > 0) {
      Draggable.create(sliderRef.current, {
        type: 'scrollTop',
        bounds: parentRef.current,
      })
    }
  }, [sliderHeight])

  return (
    <ContainerVertical ref={sliderRef}>
      {children && children}
    </ContainerVertical>
  )
}

export default FlexVerticalDrag
