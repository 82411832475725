import React, { useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { TweenMax } from 'gsap'
import {
  Bar,
  BarContainer,
  Container,
  NavigationLink,
  ExternalLink,
  NavigationCopy,
} from './styled'

interface Props {
  text: string
  time: number
  url: string
  variant: string
  position: 'left' | 'right'
  delay?: number
}

export const SideNavigationLink: React.FC<Props> = ({
  delay,
  text,
  url,
  time,
  variant,
  position,
}) => {
  const refBar = useRef(null)
  const refCont = useRef(null)
  const refLink = useRef(null)
  const navigationText = useRef(null)

  useLayoutEffect(() => {
    TweenMax.fromTo(
      refBar.current,
      time,
      {
        opacity: 0,
        width: 0,
      },
      {
        delay,
        ease: 'sine.out',
        opacity: 1,
        width: '100%',
      },
    )
  })
  useLayoutEffect(() => {
    TweenMax.fromTo(
      navigationText.current,
      time,
      {
        opacity: 0,
      },
      {
        delay,
        ease: 'sine.out',
        opacity: 1,
      },
    )
  }, [])

  return (
    <Container position={position}>
      <BarContainer ref={refCont}>
        <Bar variant={variant} ref={refBar} />
      </BarContainer>
      {url.includes('http') && (
        <ExternalLink
          variant={variant}
          ref={refLink}
          href={url}
          position={position}
          data-url
        >
          <NavigationCopy ref={navigationText} data-url>
            {text}
          </NavigationCopy>
        </ExternalLink>
      )}
      {!url.includes('http') && (
        <NavigationLink
          variant={variant}
          ref={refLink}
          to={url}
          position={position}
          data-url
        >
          <NavigationCopy ref={navigationText} data-url>
            {text}
          </NavigationCopy>
        </NavigationLink>
      )}
    </Container>
  )
}

SideNavigationLink.defaultProps = {
  delay: 0,
  position: 'left',
}

SideNavigationLink.propTypes = {
  delay: PropTypes.number,
  text: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
}
