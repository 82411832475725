/* eslint-disable indent */

import styled, { css, StyledComponent } from '@xstyled/styled-components'
import { variant, breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'

export const Paragraph: StyledComponent<
  'p',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.p`
  ${variant({
    prop: 'variant',
    variants: {
      body: css`
        font-size: ${proportion(32, 'handheld')};
        line-height: ${proportion(48, 'handheld')};

        ${breakpoints({
          md: css`
            font-size: ${proportion(16, 'desktop')};
            line-height: ${proportion(28, 'desktop')};
            letter-spacing: 0.1rem;
            margin-bottom: 32px;
          `,
          xl: css`
            font-size: 16px;
            line-height: 28px;
          `,
        })}
      `,

      bodyLarge: css`
        margin: 0;

        ${breakpoints({
          sm: css`
            font-size: ${proportion(80, 'desktop')};
            line-height: ${proportion(80, 'desktop')};
          `,
          md: css`
            font-size: ${proportion(22, 'desktop')};
            line-height: ${proportion(32, 'desktop')};
          `,
          xl: css`
            font-size: 22px;
            line-height: 32px;
          `,
        })}
      `,

      link: css`
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;
        display: inline-block;
        position: relative;
        font-size: ${proportion(26, 'desktop')};
        line-height: ${proportion(26, 'desktop')};
        margin-bottom: ${proportion(12, 'desktop')};
        font-family: title;
        text-transform: uppercase;
        letter-spacing: 0;

        &:before {
          background-color: light;
          bottom: -2px;
          content: '';
          height: 1px;
          left: 0;
          opacity: 0.6;
          position: absolute;
          transform: scaleX(${(props) => (props.active ? 1 : 0)});
          transform-origin: left;
          transition: transform 0.2s ease-in-out;
          width: 100%;
        }

        &:hover:before {
          transform: scaleX(1);
        }
      `,
    },
  })}
`

export const P = Paragraph
