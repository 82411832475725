import React, { useState, useRef, useEffect } from 'react'
import PubSub from 'pubsub-js'
import { List, PartnerLink, Title, Bar, Li, ListContainer } from './styled'
import PropTypes from 'prop-types'
import { APP_PARTNER_SELECT } from '@/events'
import { Transition } from 'react-transition-group'
import { useContentInProp } from '@/hooks/useContentInProp'
import gsap from 'gsap'
import FlexVerticalDrag from '@/components/Draggers/FlexVerticalDrag'

interface Props {
  partners: string[]
}

// PubSub.publish(APP_PARTNER_SELECT, partner)

const PartnerList: React.FC<Props> = ({ partners }) => {
  const inProp = useContentInProp()
  const listRef = useRef(null)
  const titleRef = useRef(null)
  const containerRef = useRef(null)

  const [selected, setSelected] = useState(null)
  function onClickItem(item) {
    setSelected(item)
  }

  function onEnter() {
    const elements = [titleRef.current].concat(
      Array.from(listRef.current.children),
    )

    gsap.fromTo(
      elements,
      1,
      {
        opacity: 0,
        x: 20,
      },
      {
        opacity: 1,
        x: 0,
        delay: 0.75,
        ease: 'power2.out',
        stagger: {
          each: 0.024,
        },
      },
    )
  }

  useEffect(() => {
    if (selected !== null) {
      PubSub.publish(APP_PARTNER_SELECT, selected)
    }
  }, [selected])

  return (
    <Transition in={inProp} appear onEnter={onEnter} timeout={1000}>
      <>
        <Title ref={titleRef}>Our Partners</Title>
        <ListContainer ref={containerRef}>
          <FlexVerticalDrag parentRef={containerRef}>
            <List ref={listRef}>
              <>
                {partners &&
                  partners.map((partner) => (
                    <Li key={partner} data-url selected={selected === partner}>
                      <Bar data-url />
                      <PartnerLink
                        onClick={() => onClickItem(partner)}
                        data-url
                      >
                        {partner}
                      </PartnerLink>
                    </Li>
                  ))}
              </>
            </List>
          </FlexVerticalDrag>
        </ListContainer>
      </>
    </Transition>
  )
}

PartnerList.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.string).isRequired,
}

PartnerList.defaultProps = {}

export default PartnerList
