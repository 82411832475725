import styled from '@xstyled/styled-components'

export const SelectorWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50vh;
`
export const ActiveEntryText = styled.h3`
  font-family: primary;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  padding-bottom: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  padding-left: 5px;
`
export const BackgroundWrap = styled.div`
  position: absolute;
  width: 100vw;
  top: 0rem;
  left: 0;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: primary;
  text-transform: uppercase;
  padding: 2rem;
  opacity: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
`
export const BackgroundWrapInner = styled.div`
  max-height: 40vh;
  overflow: scroll;
`

export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: secondary;
`
export const DropDownEntry = styled.div``
