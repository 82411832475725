import React, { useRef, useEffect, ReactNode, useState } from 'react'
import PubSub from 'pubsub-js'
import Helmet from 'react-helmet'
import { APP_VIEW_UPDATE } from '@/events'
import { StaticQuery, graphql } from 'gatsby'
import { Container, ContainerNoPadding } from './styled'
import gsap from 'gsap'
import contentHeight from '@/modules/utils/contentHeight'
import PropTypes from 'prop-types'
import containerWidth from '@/modules/utils/containerWidth'

interface Props {
  children: ReactNode
  scrollable?: boolean
  noPaddingMobile?: boolean
  useScaling?: boolean
  withStyleScrollingHighJack?: boolean
}

let isInitial = true
const mobileBreakpoint = 960
const isServer = typeof window === 'undefined'
let interval

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
/*
 *  - Events:
 *    - publish:
 *      - APP_VIEW_UPDATE (initial and on window resize)
 */
const PageWrapper: React.FC<Props> = ({
  children,
  scrollable,
  noPaddingMobile,
  useScaling,
  withStyleScrollingHighJack,
}) => {
  const [state, setState] = useState({
    currentSlide: 1,
    mobileView: false,
  })

  const [scaleFactor, setScaleFactor] = useState(1)
  const containerRef = useRef(null)

  const checkHeight = () => {
    if (containerRef.current) {
      const maxheight = window.innerHeight - 190

      const sf = Number(
        (maxheight / containerRef.current.clientHeight).toFixed(2),
      )

      /* eslint-disable no-restricted-globals */
      if (sf >= 1 && scaleFactor < 1) {
        setScaleFactor(1)
        gsap.set(containerRef.current, {
          scale: 1,
          transformOrigin: '50% 50%',
        })
      }
      if (sf < 1 && !isNaN(sf) && isFinite(sf) && sf !== scaleFactor) {
        setScaleFactor(sf)

        gsap.set(containerRef.current, {
          scale: sf,
          transformOrigin: '50% 50%',
        })
      }
    }
  }
  const makeScrollable = () => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${contentHeight()}px`
      containerRef.current.style.overflow = 'auto'
    }
  }

  if (!scrollable) {
    if (useScaling) {
      checkHeight()
      if (interval) {
        clearInterval(interval)
      }
      interval = setInterval(checkHeight, 500)
    }
  } else {
    makeScrollable()
  }

  const onResize = () => {
    const mobileView = document.documentElement.clientWidth <= mobileBreakpoint
    if (!isServer && containerRef.current) {
      containerRef.current.style.maxWidth = `${containerWidth()}px`
    }
    const eventData = mobileView ? 'mobile' : 'desktop'
    if (
      isInitial ||
      (mobileView && !state.mobileView) ||
      (!mobileView && state.mobileView)
    ) {
      PubSub.publish(APP_VIEW_UPDATE, eventData)
    }
    setState({
      ...state,
      mobileView,
    })
    isInitial = false
  }

  const addDomEvents = () => {
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    window.removeEventListener('resize', onResize)
    if (interval) {
      clearInterval(interval)
    }
  }

  const { mobileView } = state
  useEffect(() => {
    addDomEvents()
    onResize()
    return () => {
      removeDomEvents()
    }
  }, [mobileView])

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <>
          <Helmet
            meta={[
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'description',
              },
              {
                content: 'banking, cloud-native, core-banking',
                name: 'keywords',
              },
              {
                content: data.site.siteMetadata.title,
                name: 'og:title',
              },
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'og:description',
              },
              {
                content: '/images/share-image.jpg',
                name: 'og:image',
              },
              {
                content: '#da532c',
                name: 'msapplication-TileColor',
              },
              {
                content: '/images/favicon/browserconfig.xml',
                name: 'msapplication-config',
              },
              {
                content: '#ffffff',
                name: 'theme-color',
              },
              {
                content: 'width=device-width, initial-scale=0.95',
                name: 'viewport',
              },
            ]}
            link={[
              {
                href: '/fonts.css',
                rel: 'stylesheet',
                type: 'text/css',
              },
              {
                href: '/images/favicon/apple-touch-icon.png',
                rel: 'apple-touch-icon',
                sizes: '180x180',
              },
              {
                href: '/images/favicon/favicon-32x32.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
              },
              {
                href: '/images/favicon/favicon-16x16.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
              },
              {
                href: '/images/favicon/site.webmanifest',
                rel: 'manifest',
              },
              {
                href: '/images/favicon/safari-pinned-tab.svg',
                rel: 'mask-icon',
                color: '#5bbad5',
              },
              {
                href: '/images/favicon/favicon.ico',
                rel: 'shortcut icon',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>

          {noPaddingMobile ? (
            <>
              {withStyleScrollingHighJack && (
                <ContainerNoPadding ref={containerRef}>
                  {children}
                </ContainerNoPadding>
              )}
              {!withStyleScrollingHighJack && (
                <ContainerNoPadding>{children}</ContainerNoPadding>
              )}
            </>
          ) : (
            <Container ref={containerRef}>{children}</Container>
          )}
        </>
      )}
    />
  )
}

PageWrapper.defaultProps = {
  scrollable: false,
  noPaddingMobile: false,
  useScaling: true,
  withStyleScrollingHighJack: true,
}

PageWrapper.propTypes = {
  scrollable: PropTypes.bool,
  noPaddingMobile: PropTypes.bool,
  useScaling: PropTypes.bool,
  withStyleScrollingHighJack: PropTypes.bool,
}

export default PageWrapper
