import React, { useRef, useState, useEffect } from 'react'
import styled from '@xstyled/styled-components'
import gsap from 'gsap'

interface Props {
  variant: string
  color: '#000' | '#fff'
}

const ChevronIcon: React.FC<{ open: boolean }> = ({ open = false }) => {
  const chevronRef = useRef(null)
  const timeLineRef = useRef(null)
  const [init, setInit] = useState(true)
  const onOpen = () => {
    const tl = gsap.timeline()
    tl.fromTo(
      chevronRef.current,
      0.5,
      { rotate: 0 },
      { transformOrigin: 'center', rotate: 180 },
    )
    return tl
  }

  const onClose = () => {
    const tl = gsap.timeline()
    tl.fromTo(
      chevronRef.current,
      0.5,
      { rotate: 180 },
      { transformOrigin: 'center', rotate: 0 },
    )
    return tl
  }

  const createTimeline = (toOpen) => {
    if (toOpen) {
      return onOpen()
    }

    return onClose()
  }

  useEffect(() => {
    let tl = null
    // eslint-disable-next-line
    timeLineRef.current?.kill()
    if (init) {
      setInit(false)
    } else {
      tl = createTimeline(open)
    }

    if (tl && chevronRef) {
      tl.play()
    }
  }, [open, chevronRef])

  return (
    <ChevronIconWrap data-url=" ">
      <Graphic width="21px" height="13px">
        <path
          ref={chevronRef}
          fillRule="evenodd"
          fill="rgb(255, 255, 255)"
          d="M10.695,12.308 L12.793,10.233 L12.783,10.222 L20.662,2.407 L18.563,0.331 L10.684,8.147 L2.739,0.265 L0.640,2.341 L8.586,10.222 L8.576,10.233 L10.674,12.308 L10.684,12.298 L10.695,12.308 Z"
        />
      </Graphic>
    </ChevronIconWrap>
  )
}

const ChevronIconWrap = styled.div`
  width: 15px;
  height: 15px;
  color: secondary;
`

const Graphic = styled.svg``

export default ChevronIcon
